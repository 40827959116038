import { useState, useEffect } from "react";

export const useScrollThreshold = (threshold = -50) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { y } = document.body.getBoundingClientRect();
      setIsScrolled(y < threshold);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  return isScrolled;
};
