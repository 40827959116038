import { Link } from "@components/atoms";
import { usePathname } from "next/navigation";
import { FC, Dispatch, SetStateAction, useState, useMemo, useRef, useCallback } from "react";
import { ExitIcon } from "../../../../../../atoms/ExitIcon";
import { Logo } from "../../../../../../atoms/Logo";
import { StandardPadding } from "../../../../../../templates/StandardPadding";
import { MenuName, NavbarType, NavLinks as NavLinksType } from "../../Navbar.types";
import MobileNavArrow from "./components/MobileNavArrow/MobileNavArrow";
import PushLeft from "./components/PushLeft/PushLeft";
import {
  FadeScreen,
  FullScreen,
  LI1,
  LI3,
  MobileHeading,
  MobileReDirectPopup,
  NavButtonMobile,
  StandaloneLI,
  TabletHeading,
} from "./MobileNav.styles";
import { Anchor, Padding, LogoContainer, MenuWrapper, UL, NarrowLI } from "../../Navbar.styles";
import { convertNewlinesToBreaks, useHandleLocaleSwitch, useLocale } from "@hooks";
import { MobileNavLink } from "./MobileNavLink";
import { ExternalRoutes, StaticRoutes } from "@routes";
import router from "next/router";
import { BetterButton } from "components/atoms/BetterButton";
import { P1 } from "components/atoms/Paragraph";

const MobileNav: FC<
  React.PropsWithChildren<{
    setOpenMobileNav: Dispatch<SetStateAction<boolean>>;
    links: NavLinksType;
    hideLinks?: boolean;
    hideRegionSelector?: boolean;
  }>
> = ({ setOpenMobileNav, links, hideLinks = false, hideRegionSelector = false }) => {
  const [openMenu, setOpenMenu] = useState(MenuName.blank);
  const pathname = usePathname();
  const { locale } = useLocale();
  const isJapanLive = process.env.JAPAN_SITE_LIVE === "true";
  const navButtonText =
    locale === "us" ? "Contact us" : locale === "jp" ? "お問い合わせ" : "Get a quote";

  const handleLocaleSwitch = useHandleLocaleSwitch();

  const handleClick = (slug) => {
    if (slug.replace(/\//g, "") === pathname.replace(/\//g, "")) {
      setOpenMobileNav(false);
    }
  };

  const [showDaiIchiReDirect, setShowDaiIchiRedirect] = useState(false);
  const redirectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const triggerDaiIchiReDirect = useCallback(() => {
    setShowDaiIchiRedirect(true);
    redirectTimeoutRef.current = setTimeout(() => {
      setShowDaiIchiRedirect(false);
      router.push(ExternalRoutes.daiichiSass);
    }, 3000);
  }, [setShowDaiIchiRedirect]);

  const closeDaiIchiRedirect = useCallback(() => {
    setShowDaiIchiRedirect(false);
    if (redirectTimeoutRef.current) {
      clearTimeout(redirectTimeoutRef.current);
      redirectTimeoutRef.current = null;
    }
  }, [setShowDaiIchiRedirect]);

  const filteredLinks = useMemo(
    () =>
      links.filter((link) => {
        if (!hideLinks) {
          return true;
        }

        return !hideRegionSelector && link.title === "Region";
      }),
    [hideLinks, hideRegionSelector, links],
  );

  return (
    <FullScreen>
      <Padding>
        <Link passHref href="/" legacyBehavior>
          <LogoContainer onClick={() => handleClick("/")}>
            <Logo id="yulife-logo-fullscreen" color="#ffffff" />
          </LogoContainer>
        </Link>
        <MenuWrapper>
          <UL>
            <NarrowLI onClick={() => setOpenMobileNav(false)}>
              <ExitIcon />
            </NarrowLI>
          </UL>
        </MenuWrapper>
      </Padding>
      <StandardPadding>
        <ul>
          {locale === "jp" && isJapanLive && (
            <>
              <StandaloneLI onClick={triggerDaiIchiReDirect}>
                <Anchor>商品一覧</Anchor>
              </StandaloneLI>
              <StandaloneLI>
                <Link passHref href={StaticRoutes.aboutUs} legacyBehavior>
                  <Anchor>YuLifeについて</Anchor>
                </Link>
              </StandaloneLI>
            </>
          )}
          {filteredLinks?.map((linkGroup, index) => (
            <div key={index}>
              {linkGroup.primaryLinks.length > 0 && (
                <>
                  <LI1
                    onClick={() =>
                      setOpenMenu(openMenu === linkGroup.title ? MenuName.blank : linkGroup.title)
                    }
                  >
                    {linkGroup.titleTranslation || linkGroup.title}
                    <MobileNavArrow isOpen={openMenu === linkGroup.title} />
                  </LI1>
                  <ul>
                    {linkGroup.primaryLinks
                      .filter((item) => item.isLive === undefined || item.isLive === true)
                      .map((linkProps) => (
                        <PushLeft
                          key={linkProps.link}
                          hasEntered={openMenu === linkGroup.title}
                          transitionDelay={50}
                        >
                          <MobileNavLink
                            handleLocaleSwitch={handleLocaleSwitch}
                            handleClick={handleClick}
                            {...linkProps}
                          />
                        </PushLeft>
                      ))}
                  </ul>
                </>
              )}
              {linkGroup.secondaryLinks && (
                <>
                  <LI1
                    onClick={() =>
                      setOpenMenu(
                        openMenu === linkGroup.secondaryTitle
                          ? MenuName.blank
                          : linkGroup.secondaryTitle,
                      )
                    }
                  >
                    {linkGroup.secondaryTitle}
                    <MobileNavArrow isOpen={openMenu === linkGroup.secondaryTitle} />
                  </LI1>
                  <ul>
                    {linkGroup.secondaryLinks
                      .filter((item) => item.isLive === undefined || item.isLive === true)
                      .map((linkProps) => (
                        <PushLeft
                          key={linkProps.link}
                          hasEntered={openMenu === linkGroup.secondaryTitle}
                          transitionDelay={50}
                        >
                          <MobileNavLink handleClick={handleClick} {...linkProps} />
                        </PushLeft>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ))}
          {hideLinks ? null : (
            <>
              {locale !== "jp" && (
                <StandaloneLI>
                  <Link passHref href="/careers/" legacyBehavior>
                    <Anchor>We’re hiring!</Anchor>
                  </Link>
                </StandaloneLI>
              )}
              <LI3 onClick={() => handleClick("/get-a-quote/")}>
                <Link passHref href="/get-a-quote/" legacyBehavior>
                  <NavButtonMobile buttonType={NavbarType.primaryInverted}>
                    {navButtonText}
                  </NavButtonMobile>
                </Link>
              </LI3>
            </>
          )}
        </ul>
        {showDaiIchiReDirect && locale === "jp" ? (
          <>
            <FadeScreen />
            <MobileReDirectPopup>
              <TabletHeading pt="0" pb="0" fontSize="1.5rem" bold>
                これより先は第一生命のページへ移動します。
              </TabletHeading>
              <MobileHeading pt="0" pb="0" fontSize="1.5rem" bold>
                {convertNewlinesToBreaks("これより先は第一生命\\nのページへ移動します。")}
              </MobileHeading>
              <P1 pt="1rem" pb="1rem">
                自動で切り替わります。
              </P1>
              <BetterButton text="閉じる" onClick={closeDaiIchiRedirect} />
            </MobileReDirectPopup>
          </>
        ) : null}
      </StandardPadding>
    </FullScreen>
  );
};

export default MobileNav;
