export enum ExternalRoutes {
  faq = "https://faq.yulife.com",
  faqChat = "https://faq.yulife.com/en/?website=true",
  adviserSupportHub = "https://adviserhub.yulife.com/",
  daiichiSass = "https://japan.yulife.com/saas",
  trustpilotReview = "https://uk.trustpilot.com/review/www.yulife.com",
  capteraReview = "https://www.capterra.co.uk/software/1031164/yulife",
  daiIchiFormLink = "https://www.dai-ichi-life.co.jp/personal_date/index.html",
  joshJapanInterview = "https://www.youtube.com/watch?v=vddDKKzsuv4",
}
