import { YuLifeLocale } from "@hooks";
import { AnnouncementVariantsMap } from "components/molecules/AnnouncementBar/types";

export const getActiveAnnouncementKey = (
  announcementVariants: AnnouncementVariantsMap,
  locale: YuLifeLocale,
) => {
  const now = new Date();

  return (
    Object.keys(announcementVariants).find((key) => {
      const { startDate, endDate, locales } = announcementVariants[key];
      const isWithinDates = now >= startDate && now <= endDate;
      const isLocaleSupported = !locales || locales.includes(locale);
      return isWithinDates && isLocaleSupported;
    }) || null
  );
};
