import { FC, useEffect, useRef, useState, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";
import { NavbarType } from "../organisms/Navigation/components/Navbar/Navbar.types";
import { Footer } from "../organisms/Footer";
import { useActiveAnnouncementVariant, useClientOnly, useLocale, YuLifeLocale } from "hooks";
import dynamic from "next/dynamic";
import { Vimeo } from "@services";
import { GTMPreferences, useCookiePreferences } from "hooks/useCookiePreferences";
import { ClientOnly } from "components/templates/ClientOnly";
import { AsciiYugi } from "components/templates/AsciiYugi";
import { UserLocaleModal } from "components/organisms/Modal";
import { NavBar } from "components/molecules/NavBar";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { color, getCookie, getNavigatorLocale } from "@utils";
import { LatestCaseStudy } from "utils/server";
import { P1 } from "components/atoms/Paragraph";
import { BetterButton } from "components/atoms/BetterButton";
import { ExternalRoutes } from "routes/ExternalRoutes";
import { AnnouncementBar } from "components/molecules/AnnouncementBar/AnnouncementBar";
import { announcementVariants } from "components/molecules/AnnouncementBar/AnnouncementVariants";

const CookiesBar = dynamic(() => import("../organisms/CookiesBar"), { loading: () => null });

export const Page: FC<
  React.PropsWithChildren<{
    hero?: JSX.Element;
    isInverted?: boolean;
    background?: string;
    navColor?: string;
    fullScreenHero?: boolean;
    hidePressBar?: boolean;
    hideFooter?: boolean;
    hideLinks?: boolean;
    hideRegionSelector?: boolean;
    latestCaseStudy?: LatestCaseStudy;
    topPageToolbar?: JSX.Element;
    daiIchiLogo?: boolean;
    showAnnouncementBar?: boolean;
  }>
> = ({
  hero,
  isInverted,
  background,
  fullScreenHero,
  hidePressBar = false,
  hideFooter = false,
  hideLinks = false,
  hideRegionSelector = false,
  children,
  latestCaseStudy,
  topPageToolbar,
  daiIchiLogo = false,
  showAnnouncementBar = false,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();

  const shouldOpenLocaleModal = query.get("openLocaleModal") === "true";

  const { locale } = useLocale();

  useEffect(() => {
    const utmString = window.location.href.match(/(\&|\?)utm_[A-Za-z]+=[A-Za-z0-9]+/gi);
    if (utmString) {
      try {
        sessionStorage.setItem("utm", utmString.join(""));
      } catch (e) {
        // do nothing
      }
    }

    if (process.env.ENV_NAME === "production") {
      console.info(AsciiYugi);
    }
  }, []);

  const { hasMounted } = useClientOnly();

  const [videoId, setVideoId] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const preloadVideo = (vimeoId: string) => {
    setVideoId(vimeoId);
  };

  const triggerVideo = () => {
    setShowVideo(true);
  };

  const [showUserLocaleModal, setShowUserLocaleModal] = useState(false);

  useEffect(() => {
    setShowUserLocaleModal(shouldOpenLocaleModal);
  }, [shouldOpenLocaleModal]);

  const closeLocaleModal = () => {
    setShowUserLocaleModal(false);
  };

  const [showCookiesBar, setShowCookiesBar] = useState(false);
  const [openOnCookiesModal, setOpenOnCookiesModal] = useState(false);
  const [showDaiIchiReDirect, setShowDaiIchiRedirect] = useState(false);
  const redirectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const announcement = useActiveAnnouncementVariant(announcementVariants, locale);

  const triggerDaiIchiReDirect = useCallback(() => {
    if (process.env.JAPAN_SITE_LIVE !== "true" && locale === "jp") {
      return;
    }
    setShowDaiIchiRedirect(true);
    redirectTimeoutRef.current = setTimeout(() => {
      setShowDaiIchiRedirect(false);
      router.push(ExternalRoutes.daiichiSass);
    }, 3000);
  }, [router, setShowDaiIchiRedirect, locale]);

  const closeDaiIchiRedirect = useCallback(() => {
    setShowDaiIchiRedirect(false);
    if (redirectTimeoutRef.current) {
      clearTimeout(redirectTimeoutRef.current);
      redirectTimeoutRef.current = null;
    }
  }, [setShowDaiIchiRedirect]);

  const { updateCookiePreferences } = useCookiePreferences();

  useEffect(() => {
    if (!hasMounted) {
      return;
    }

    setShowDaiIchiRedirect(false);

    const nextLocaleCookie = getCookie("NEXT_LOCALE");

    if (!nextLocaleCookie) {
      const navigatorLocale = getNavigatorLocale();

      if (navigatorLocale) {
        if (navigatorLocale !== locale) {
          setShowUserLocaleModal(true);
        }
      }
    }

    try {
      if (window.localStorage.getItem("gdpr") === null) {
        setShowCookiesBar(window.localStorage.getItem("gdpr") === null);
      } else {
        updateCookiePreferences(JSON.parse(window.localStorage.getItem("gdpr")) as GTMPreferences);
      }

      // We want to remove the old cookies check. One day we can remove this code.
      if (window.localStorage.getItem("accept_cookies")) {
        window.localStorage.removeItem("accept_cookies");
      }
    } catch (e) {
      // do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, pathname, router, updateCookiePreferences]);

  return (
    <ClientOnly>
      <div style={{ background }}>
        <ThemeContext.Provider
          value={{
            preloadVideo,
            triggerVideo,
            openCookieModal: () => {
              setShowCookiesBar(true);
              setOpenOnCookiesModal(true);
            },
          }}
        >
          <NavContainer>
            {topPageToolbar ? <>{topPageToolbar}</> : null}
            {showAnnouncementBar && announcement ? <AnnouncementBar {...announcement} /> : null}
            <NavBar
              navbarType={isInverted ? NavbarType.primaryInverted : NavbarType.primary}
              hideLinks={hideLinks}
              hideRegionSelector={hideRegionSelector}
              latestCaseStudy={latestCaseStudy}
              topToolbar={!!topPageToolbar}
              hasDaiIchiLogo={daiIchiLogo}
              triggerDaiIchiRedirect={triggerDaiIchiReDirect}
              announcementBar={showAnnouncementBar && !!announcement}
            />
          </NavContainer>
          <ClientOnly>
            <Vimeo
              isOpen={showVideo}
              vimeoId={videoId}
              onClose={() => {
                setShowVideo(false);
                setVideoId("");
              }}
            />
            <UserLocaleModal openModal={showUserLocaleModal} closeModal={closeLocaleModal} />
          </ClientOnly>
          {showAnnouncementBar && <Spacer />}
          {hero ? (
            <Header role="banner" fullScreen={fullScreenHero}>
              {hero}
            </Header>
          ) : null}
          {showCookiesBar ? (
            <CookiesBar
              onSave={() => setShowCookiesBar(!showCookiesBar)}
              openOnModal={openOnCookiesModal}
            />
          ) : null}
          {showDaiIchiReDirect && locale === "jp" ? (
            <>
              <FadeScreen />
              <ReDirectPopup>
                <P1 pt="0" pb="0" fontSize="1.5rem" bold>
                  これより先は第一生命のページへ移動します。
                </P1>
                <P1 pt="1rem" pb="1rem">
                  自動で切り替わります。
                </P1>
                <BetterButton text="閉じる" onClick={closeDaiIchiRedirect} />
              </ReDirectPopup>
            </>
          ) : null}
          <Main id="main" role="main">
            {children}
          </Main>
          {hideFooter ? null : <Footer hidePressBar={hidePressBar} />}
        </ThemeContext.Provider>
      </div>
    </ClientOnly>
  );
};

const NavContainer = styled.div`
  position: sticky;
  top: -0.1rem;
  z-index: 10;
  height: 8rem;

  @media only screen and (max-width: 45rem) {
    height: 6.4375rem;
  }

  @media only screen and (max-width: 30rem) {
    height: 5.5rem;
  }
`;

const Header = styled.header<{ fullScreen?: boolean }>`
  position: relative;
  max-width: 100vw;
  ${(p) =>
    p.fullScreen
      ? `
      margin-top: -8.75rem;

      @media only screen and (max-width: 45rem) {
        margin-top: -6.4rem;
      }

      @media only screen and (max-width: 30rem) {
        margin-top: -4.75rem;
      }
      `
      : null}
`;

const Main = styled.main`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
`;

const ReDirectPopup = styled.div`
  position: fixed;
  top: calc(50vh - 4rem);
  left: calc(50vw - 20rem);
  background: ${color.B210};
  text-align: left;
  z-index: 10;
  width: 41.5rem;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid ${color.B230};
  display: flex;
  flex-direction: column;
`;

const FadeScreen = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 70%;
  z-index: 10;
`;

const Spacer = styled.div`
  height: 0;

  @media only screen and (max-width: 29.5rem) {
    height: 3rem;
  }
`;
