interface MetaImage {
  feature_image?: string;
  og_image?: string;
  twitter_image?: string;
}
interface Metadata {
  [key: string]: {
    description?: string;
    image?: MetaImage;
    imageAlt?: string;
    keywords?: string[];
    noIndex: boolean;
    title: string;
    url: string;
  };
}
export const metadataJP: Metadata = {
  app: {
    description:
      "YuLifeは日々の健康活動をリワードと交換できる“ちょっと新しい”福利厚生サービス。従業員の皆様のエンゲージメントを高め、組織全体の活性化と成長を実現します。",
    image: {},
    imageAlt: "yulife-app",
    keywords: ["app", "wellbeing", "benefits", "rewards", "workplace"],
    noIndex: false,
    title: "YuLifeアプリ | YuLife",
    url: "https://yulife.com/jp/features/employee-app",
  },
  index: {
    description:
      "YuLife（ユーライフ）は、従業員の定着率・エンゲージメント・生産性UPを実現するオールインワンアプリです。グローバル導入社数1,000社突破・No.1の福利厚生サービス（英国実績）。第一生命の団体保険とYuLifeの福利厚生サービスは、従業員さまの健康で幸せな生活を実現します。",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "YuLife | 団体保険・健康増進・リワードを一つのアプリでスムーズに  ",
    url: "https://yulife.com/jp/",
  },
  "product-doc-download": {
    description:
      "YuLifeサービス概要をご請求いただきありがとうございます。本資料ではYuLifeアプリ、HRポータル、健康経営優良法人認定サポートについて、YuLifeの導入効果についてご説明いたします。",
    image: {},
    imageAlt: "yulife-product-doc-download",
    keywords: [],
    noIndex: false,
    title: "サービス概要請求 | YuLife",
    url: "https://yulife.com/jp/product-doc-download/",
  },
  rewards: {
    description:
      "ゲームを通して、健康生活を促進！YuLifeで貯めたコインはクーポン券と交換したり、慈善団体に寄付するなど、人にも環境にも優しい福利厚生を実現します。",
    image: {},
    imageAlt: "yulife-rewards",
    keywords: [],
    noIndex: false,
    title: "従業員リワード制度 | YuLife",
    url: "https://yulife.com/jp/features/rewards/",
  },
};
