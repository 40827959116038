import { StaticRoutes } from "@routes";
import { config } from "@utils";

import { NavIconNames } from "../../../../atoms/NavIcon";
import { MenuName, NavLinks } from "./Navbar.types";

const jpNavLinks: NavLinks = [
  {
    title: MenuName.featuresJP,
    id: "features-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "YuLifeアプリ",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "リワード制度",
      },
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "YuLifeアプリ",
      },
      {
        link: StaticRoutes.featuresPortal,
        text: "HRポータル",
      },
    ],
  },
  {
    title: MenuName.resourcesJP,
    id: "resources-nav-title",
    primaryLinks: [
      {
        link: StaticRoutes.blog,
        text: "ブログ",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "他社成功事例",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "ニュース・イベント",
      },
    ],
  },
  {
    title: MenuName.region,
    titleTranslation: "地域",
    id: "login-nav-title",
    primaryLinks: [
      {
        locale: "uk",
        localeLink: true,
        link: "GB-UKM",
        text: "UK",
      },
      {
        locale: "us",
        localeLink: true,
        text: "US",
        link: "US",
      },
      {
        locale: "za",
        localeLink: true,
        text: "SA",
        link: "ZA",
      },
      {
        locale: "jp",
        localeLink: true,
        text: "JP",
        link: "JP",
      },
    ],
  },
];

export default jpNavLinks;
