import {
  AnnouncementVariant,
  AnnouncementVariantsMap,
} from "components/molecules/AnnouncementBar/types";
import { useMemo } from "react";
import { getActiveAnnouncementKey } from "utils/announcements";
import { YuLifeLocale } from "./useLocale";

export const useActiveAnnouncementVariant = (
  announcementVariants: AnnouncementVariantsMap,
  locale: YuLifeLocale,
): AnnouncementVariant | null => {
  const activeAnnouncementVariantKey = useMemo(
    () => getActiveAnnouncementKey(announcementVariants, locale),
    [announcementVariants, locale],
  );

  return activeAnnouncementVariantKey ? announcementVariants[activeAnnouncementVariantKey] : null;
};
