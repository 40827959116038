import { MegaMenuCallout } from "components/molecules/MegaMenuCallout";
import { MegaMenuImageBlock } from "components/molecules/MegaMenuImageBlock";
import { MegaMenuLargeBlock } from "components/molecules/MegaMenuLargeBlock";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { Link } from "@components/atoms";
import { FC } from "react";
import styled from "styled-components";
import {
  Column,
  TextBlock,
  Asset,
  ColumnContainer,
  RightColumn,
  Container,
  ResizableContainer,
} from "./styles";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";
import { useTranslations } from "next-intl";
import { LatestCaseStudy } from "utils/server";
import { convertNewlinesToBreaks, useLocale } from "@hooks";
import { ExternalRoutes } from "@routes";

export const MegaMenuResources: FC<{ latestCaseStudy?: LatestCaseStudy }> = ({
  latestCaseStudy,
}) => {
  const t = useTranslations("shared/mega-menu");
  const { resources } = useLocaleData(data);
  const { locale } = useLocale();

  return (
    <ResizableContainer>
      <StyledContainer>
        <ColumnContainer maxHeight="26.178rem">
          <Column>
            <TextBlock>
              <MegaMenuLargeBlock
                title={t<string>("resources.left.title")}
                desc={t<string>("resources.left.desc")}
              />
            </TextBlock>
            <Asset
              width={242}
              height={453}
              src={t<string>("resources.left.asset.src")}
              alt={t<string>("resources.left.asset.alt")}
            />
          </Column>
          <MiddleColumn>
            {resources
              .filter((item) => item.isLive === undefined || item.isLive === true)
              .map((resource) => (
                <Link key={resource.key} href={resource.href}>
                  <MegaMenuSmallBlock title={resource.title} />
                </Link>
              ))}
          </MiddleColumn>
          {locale === "jp" ? (
            <RightColumnRes>
              <TextBlock>
                <Link href={ExternalRoutes.joshJapanInterview}>
                  <MegaMenuImageBlock
                    title={convertNewlinesToBreaks(
                      "共同創業者Joshがビジネス\\nメディア「PIVOT」に登壇",
                    )}
                    subheading="【新着コンテンツ】"
                    image="/static/img/menu/yulife-ユーライフ-ナビゲーション-daiichi-第一生命-団体保険-福利厚生-アプリ.jpeg"
                    alt="YuLife 最新情報| YuLife ナビゲーションバー"
                    pillText="新着コンテンツ"
                    readTime="動画を見てみる"
                  />
                </Link>
              </TextBlock>
            </RightColumnRes>
          ) : (
            <RightColumnRes>
              <TextBlock>
                {latestCaseStudy ? (
                  <Link href={`${process.env.BASE_URL}/case-studies/${latestCaseStudy.slug}`}>
                    <MegaMenuImageBlock
                      title={latestCaseStudy.title}
                      subheading={t<string>("resources.right.subheading")}
                      image={latestCaseStudy.img}
                      alt={latestCaseStudy.alt}
                      pillText={t<string>("resources.right.pill")}
                      readTime={latestCaseStudy.readTime}
                    />
                  </Link>
                ) : (
                  <Link href={t<string>("resources.right.href")}>
                    <MegaMenuImageBlock
                      title={t<string>("resources.right.title")}
                      subheading={t<string>("resources.right.subheading")}
                      image={t<string>("resources.right.image")}
                      alt={t<string>("resources.right.alt")}
                      pillText={t<string>("resources.right.pill")}
                      readTime={t<string>("resources.right.read-time")}
                    />
                  </Link>
                )}
              </TextBlock>
            </RightColumnRes>
          )}
        </ColumnContainer>
        {locale !== "jp" && (
          <MegaMenuCallout
            pillText={t<string>("resources.callout.pill")}
            desc={t<string>("resources.callout.desc")}
            CTADesc={t<string>("resources.callout.cta")}
            link={t<string>("resources.callout.link")}
          />
        )}
      </StyledContainer>
    </ResizableContainer>
  );
};

const StyledContainer = styled(Container)`
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const MiddleColumn = styled(Column)`
  padding-top: 1.5rem;
  justify-content: flex-start;
`;

const RightColumnRes = styled(RightColumn)`
  padding-bottom: 3rem;
`;
