import { YuLifeLocale } from "@hooks";

export type AnnouncementVariant = {
  mainText: string | JSX.Element;
  linkText?: string;
  link?: string;
  backgroundColor?: string;
  textColor?: string;
  image?: string;
  imageAlt?: string;
  startDate: Date;
  endDate: Date;
  locales: YuLifeLocale[];
};

export type AnnouncementVariantsMap = {
  [key in AnnouncementVariants]: AnnouncementVariant;
};

export enum AnnouncementVariants {
  HubspotMigration = "HubsSpotMigration",
}
